import MultiStateBoxComponent from '../components/MultiStateBox/viewer/MultiStateBox';
import MultiStateBoxController from '../components/MultiStateBox/viewer/MultiStateBox.controller';


const MultiStateBox = {
  component: MultiStateBoxComponent,
  controller: MultiStateBoxController
};


export const components = {
  ['MultiStateBox']: MultiStateBox
};

